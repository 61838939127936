import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
   typography: {
    "fontFamily": `"Poppins", sans-serif`,
   },
   palette: {
    primary: {
     main: '#5D2FE1',
    },
   },
   components: {
    MuiButton: {
     styleOverrides: {
      root: {
         fontSize: '18px',
         textTransform: 'none',
         color: 'black',
         width: 'max-content'
      },
     },
    },
    MuiInputLabel: {
     styleOverrides: {
      root: {
         fontSize: '18px',
      }
     }
    },
    MuiInputBase: {
     styleOverrides: {
      root: {
         input: {
          padding: '12px 16px',
          fontSize: '18px',
         }
      }
     }
    },
    MuiAccordion: {
     styleOverrides: {
      root: {
         backgroundColor: 'transparent',
         boxShadow: 'none',
         '&:before': {
          backgroundColor: 'transparent'
         }
      }
     }
    },
    MuiAccordionSummary: {
     styleOverrides: {
      root: {
         padding: 0,
      }
     }
    },
    MuiAccordionDetails: {
     styleOverrides: {
      root: {
         padding: 0,
         marginBottom: '12px'
      }
     }
    },
   },
});