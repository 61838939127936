import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import api from 'services/axios';
import OtpInput from 'react-otp-input';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

import { useSnackbar } from 'context/SnackbarContext'

function LoginOTP({ formData, setIsStepOneSuccess }) {
  const openSnackbar = useSnackbar();

  const navigate = useNavigate()

  const [otpValue, setOtpValue] = useState('');
  const [isOtpError, setOtpError] = useState(false);
  const [countdown, setCountdown] = useState('00:00')
  const [otpExpDate, setOtpExpDate] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleChangeOtp = (otp) => {
    setOtpValue(otp)
  }

  const handleResend = () => {
    window.location.reload()
    // startTimer(Date.now() + (3 * 60 * 1000), setCountdown)
  }

  const handleSendOtp = async (expiredDate) => {
    try {
      await api.post('/api/otp/send', {
        user_email:
          formData && formData.email ||
          window.localStorage.getItem('user_email'),
        expired_date: expiredDate
      })
    } catch (err) {
      openSnackbar('Something went wrong!', 'error')
      window.localStorage.setItem('LOGIN_STEP_ONE_SUCCESS', 'false');
      window.location.reload()
    }
  }

  const handleCancel = () => {
    window.localStorage.setItem('LOGIN_STEP_ONE_SUCCESS', 'false');
    window.localStorage.removeItem('user_email');
    window.location.reload()
  }

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true)
      let responseData = {}
      if (window.location.pathname == '/login/test') {
        // For testing purpose
        responseData = await api.post('/api/user/poiuytr/login', {
          email: formData && formData.email,
          code: otpValue,
          channel_reference: 'DEN8R3OX',
          agent_reference: 'DENTGC21W'
        })
      } else {
        responseData = await api.post('/api/user/login', {
          email: formData && formData.email,
          code: otpValue
        })
      }
      const {
        data: {
          access_token,
          is_admin,
          role,
          channel_reference,
          agent_reference
        }
      } = responseData

      const userAccount = {
        emailAddress: formData?.email || '',
        accessToken: access_token || '',
        channelReference: channel_reference || '',
        agentReference: agent_reference || '',
        isAdmin: is_admin || false,
        role: role || '',
      }

      window.localStorage.setItem('userAccount', JSON.stringify(userAccount))

      window.localStorage.setItem('access_token', access_token)
      window.localStorage.setItem('channel_reference', channel_reference)
      window.localStorage.setItem('agent_reference', agent_reference)
      openSnackbar('Login success!', 'success');
      window.localStorage.setItem('LOGIN_STEP_ONE_SUCCESS', 'false');
      setIsSubmitting(false)
      navigate('/');
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        openSnackbar(error.response.data.message, 'error')
        setIsSubmitting(false)
        if (error.response.data.message == 'unauthorized') {
          setIsStepOneSuccess(false)
          window.localStorage.removeItem('user_email');
        }
      }
    }
  }
  let timerInterval;

  function startTimer(endTime, setCountdown) {
    if (endTime >= 0) {
      let timer = (endTime > Date.now()) ? ((endTime - Date.now()) / 1000) : 0;
      let minutes, seconds;
      if (Boolean(timer)) {
        timerInterval = setInterval(function () {
          minutes = parseInt(timer / 60, 10);
          seconds = parseInt(timer % 60, 10);

          minutes = minutes < 10 ? "0" + minutes : minutes;
          seconds = seconds < 10 ? "0" + seconds : seconds;

          setCountdown(minutes + ":" + seconds);
          if (--timer < 0) {
            timer = 0;
            setCountdown(minutes + ":" + seconds);
          }
        }, 1000);
      }
    }
  }

  function setExpiredDate() {
    clearInterval(timerInterval);
    const expiredDate = Date.now() + (3 * 60 * 1000)
    setOtpExpDate(expiredDate)
    startTimer(expiredDate, setCountdown)
  }

  useEffect(() => {
    setExpiredDate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timerInterval])

  useEffect(() => {
    if (otpExpDate) {
      handleSendOtp(otpExpDate)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.email, otpExpDate])

  return (
    <Box sx={{
      textAlign: 'center',
      padding: '0px 20px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '70vh'
    }}>
      <Typography variant='h2' sx={{ fontSize: 22, fontWeight: 'bold', mb: '12px', mt: '30px' }}>
        Input One Time Password (OTP)
      </Typography>
      <Typography variant='body1' sx={{ mb: '48px' }}>
        We have sent the verification code to your email
      </Typography>
      <StyledOtpInput
        value={otpValue}
        onChange={handleChangeOtp}
        numInputs={6}
        isOtpError={isOtpError}
      />
      <Typography variant='body1' sx={{ mb: '10px' }}>
        If you didn't receive code, click{" "}
        <span style={{ cursor: 'pointer', fontWeight: 'bold', textDecoration: 'underline' }} onClick={handleResend}>resend</span>{" "}
        or <span style={{ cursor: 'pointer', fontWeight: 'bold', textDecoration: 'underline' }} onClick={handleCancel}>cancel request</span>
      </Typography>
      <Typography variant='body1' sx={{ mb: '60px' }}>
        {countdown}
      </Typography>
      <Button
        onClick={() => handleSubmit()}
        disabled={!otpValue || isSubmitting}
        size="small"
        variant="contained"
        type="submit"
        sx={{
          m: 'auto',
          width: 210,
          padding: '8px',
          color: 'white',
          background: '#000000',
          fontWeight: 'bold',
          borderRadius: '15px',
          '&:hover': {
            filter: 'brightness(0.75)',
          },
          '&:disabled': {
            background: '#FFEFC7',
          }
        }}
      >
        {
          isSubmitting ?
            'Loading'
            :
            'Submit'
        }
      </Button>
    </Box>
  )
}

export default LoginOTP

const StyledOtpInput = styled(OtpInput)`
   margin: 0 auto;
   margin-bottom: 24px;
   input {
      background-color: ${({ value, isOtpError }) => isOtpError ? "#FFD4BC" : (value ? "#E3D9FF" : "transparent")};
      width: 100%!important;
      margin: 0 5px;
      height: 60px;
      border: ${({ isOtpError }) => isOtpError ? "1px solid #D96800" : "1px solid #FFFFFF"};
      border-radius: 10px;
      font-size: 25px;
   }
`