import React, { useState, useEffect } from 'react'

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid'
import LoginForm from "views/Login/LoginForm/LoginForm";
import LoginOTP from "views/Login/LoginOTP/LoginOTP";
import DenstvLogoWhite from 'assets/Gamers_Code_Logo_on-dark_ver.png'

function Login() {

   const [isStepOneSuccess, setIsStepOneSuccess] = useState(false)
   const [formData, setFormData] = useState('')

   const handleSuccess = (data) => {
      if (data) {
         window.localStorage.setItem('user_email', data.email)
         setFormData(data)
      }
      setIsStepOneSuccess(true)
      window.localStorage.setItem('LOGIN_STEP_ONE_SUCCESS', 'true')
   }

   useEffect(() => {
      const loginStepOneSuccess = window.localStorage.getItem('LOGIN_STEP_ONE_SUCCESS');
      loginStepOneSuccess === 'true' ? setIsStepOneSuccess(true) : setIsStepOneSuccess(false)
   }, [])

   return (
      <Box
         sx={{
            background: '#169697',
            color: 'white',
            height: '100%'
         }}
      >
         <Box
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
            sx={{
               height: '100vh',
               label: {
                  color: 'white',
                  mb: '5px',
               },
               '& .MuiTextField-root': {
                  background: 'white',
                  borderRadius: '10px',
               },
               '& .MuiInputBase-root': {
                  background: 'transparent',
               },
               '& .MuiGrid-item': {
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
               }
            }}
         >
            <Box sx={{ alignItems: 'center', padding: '30px 0' }}>
               <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                  <div style={{width: '300px', height: 'auto'}}>
                     <img src={DenstvLogoWhite} style={{width: '100%', height: '100%', objectFit: 'contain'}}  />
                  </div>
               </Box>
               {isStepOneSuccess ? (
                  <LoginOTP
                     formData={formData}
                     setIsStepOneSuccess={setIsStepOneSuccess}
                  />
               ) : (
                  <LoginForm
                     handleSuccess={handleSuccess}
                  />
               )}
            </Box>
         </Box>
      </Box>
   )
}

export default Login