import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Accordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import MuiButton from '@mui/material/Button'

import { useSnackbar } from 'context/SnackbarContext'
import { useUser } from 'context/UserContext'
import api from 'services/axios';
import MainContainer from 'components/layout/container/MainContainer'

import DiamondIcon from 'assets/DiamondIcon'
import ArrowDownIcon from 'assets/ArrowDownIcon'

function Game() {
   const openSnackbar = useSnackbar();
   const user = useUser();

   const [game, setGame] = useState([]);
   const [voucher, setVoucher] = useState({});
   const [showRedeemHistoryButton, setShowRedeemHistory] = useState(false)
   const navigate = useNavigate();
   const { id } = useParams();
   const [isLoading, setIsLoading] = useState(false)

   const getGames = async () => {
      setIsLoading(true)
      try {
         let { data: { data: games } } = await api.get('/api/game', {
            headers: {
               'access-token': `${window.localStorage.getItem('access_token')}`
            }
         });
         const res = games.find(game => game.id === parseInt(id))
         setGame(res)
         setIsLoading(false)
      } catch (error) {
         setIsLoading(false)
         openSnackbar(error.response.data.message.toString().replace('-', ' '), 'error')
         window.location.replace('/login')
      }
   }

   const getVoucher = async () => {
      setIsLoading(true)
      try {
         const { data: { result } } = await api.get(`/api/vhcr/${id}`, {
            headers: {
               'access-token': `${window.localStorage.getItem('access_token')}`
            }
         });
         setVoucher(result)
         setIsLoading(false)
      } catch (error) {
         setIsLoading(false)
         const errMessage =
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
         if (errMessage === 'not-eligible-to-redeem-for-this-period') {
            setShowRedeemHistory(true)
         }
         openSnackbar(errMessage.split('-').join(' '), 'error');
      }
   }

   useEffect(() => {
      getGames()
      !user.isAdmin && getVoucher()
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   const AccordionSummary = styled((props) => (
      <MuiAccordionSummary
         expandIcon={<ArrowDownIcon sx={{ fontSize: '0.9rem' }} />}
         {...props}
      />
   ))(({ theme }) => ({
      flexDirection: 'row-reverse',
      gap: theme.spacing(1)
   }));

   const Button = styled((props) => (
      <MuiButton
         {...props}
      />
   ))(({ theme }) => ({
      color: 'white',
      fontWeight: 'bold',
      backgroundColor: '#7B61FF',
      padding: '15px',
      position: 'fixed',
      bottom: '42px',
      left: 0,
      right: 0,
      margin: '0 auto',
      width: 'min(400px, 90%)',
      maxWidth: '400px'
   }));

   const handleRedeem = async () => {
      try {
         await api.post('/api/vhcr/clm/gen', {
            reference_number: "PARTNER-REF-NUMBER",
            email: window.localStorage.getItem('user_email'),
            vouchers: [voucher]
         }, {
            headers: {
               'access-token': `${window.localStorage.getItem('access_token')}`,
            }
         })
         openSnackbar('Successfully redeemed your voucher!', 'success');
         navigate(`/redeem/${id}`)
      } catch (error) {
         const errMessage =
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
         openSnackbar(errMessage.split('-').join(' '), 'error');
      }
   }

   const howToRedeem = (gameName) => {
      switch (gameName && gameName.toLowerCase()) {
         case 'lords mobile':
            return (
               <Typography>
                  Go to <a href="https://lordsmobile.igg.com/gifts/" target="_blank">
                     this link
                  </a>
                  <br /><br />
                  <Box sx={{width: '75vw', maxWidth: '400px', height: 'auto'}}>
                     <a href="https://storage.googleapis.com/testing_storage_cloudxier/how-to-redeem-lm-1.png" target="_blank">
                        <img style={{width: '100%', height: '100%', borderRadius: '10px'}} src="https://storage.googleapis.com/testing_storage_cloudxier/how-to-redeem-lm-1.png"></img>
                     </a>
                  </Box>
                  <br />
                  Enter your <b>IGG ID</b> and <b>Voucher Code</b> from this platform
                  <br /><br />
                  Click <b>Claim</b> button
                  <br /><br />
                  <Box sx={{width: '75vw', maxWidth: '400px', height: 'auto'}}>
                     <a href="https://storage.googleapis.com/testing_storage_cloudxier/how-to-redeem-lm-2.png" target="_blank">
                        <img style={{width: '100%', height: '100%', borderRadius: '10px'}} src="https://storage.googleapis.com/testing_storage_cloudxier/how-to-redeem-lm-2.png"></img>
                     </a>
                  </Box>
                  <br />
                  Congratulations you have successfully redeemed the voucher, please check your <b>in-game account</b> and the benefits will reflected <b>in your game account</b>
               </Typography>
            )
         case 'domino qiuqiu zumba':
            return (
               <Typography>
                  Buka game Domino Qiu Qiu Zumba pilih <b>Menu pojok kanan atas</b> dan <b>pilih pengaturan (tombol bergerigi)</b>
                  <br /><br />
                  <Box sx={{width: '75vw', maxWidth: '400px', height: 'auto'}}>
                     <a href="https://storage.googleapis.com/testing_storage_cloudxier/domino_zumba_step1.jpg" target="_blank">
                        <img style={{width: '100%', height: '100%', borderRadius: '10px'}} src="https://storage.googleapis.com/testing_storage_cloudxier/domino_zumba_step1.jpg"></img>
                     </a>
                  </Box>
                  <br />
                  Pilih <b>No. Imbalan</b>
                  <br /><br />
                  <Box sx={{width: '75vw', maxWidth: '400px', height: 'auto'}}>
                     <a href="https://storage.googleapis.com/testing_storage_cloudxier/domino_zumba_step2.jpg" target="_blank">
                        <img style={{width: '100%', height: '100%', borderRadius: '10px'}} src="https://storage.googleapis.com/testing_storage_cloudxier/domino_zumba_step2.jpg"></img>
                     </a>
                  </Box>
                  <br />
                  Masukan <b>Kode Bonus</b> yang kalian Dapatkan di platform ini lalu pilih <b>“Tukar Imbalan”</b>
                  <br /><br />
                  <Box sx={{width: '75vw', maxWidth: '400px', height: 'auto'}}>
                     <a href="https://storage.googleapis.com/testing_storage_cloudxier/domino_zumba_step3.jpg" target="_blank">
                        <img style={{width: '100%', height: '100%', borderRadius: '10px'}} src="https://storage.googleapis.com/testing_storage_cloudxier/domino_zumba_step3.jpg"></img>
                     </a>
                  </Box>
                  <br />
                  Redeem Code Bonus Berhasil <b>“Selamat anda mendapatkan  1.000.000.000 Koin”</b>
                  <br /><br />
                  <Box sx={{width: '75vw', maxWidth: '400px', height: 'auto'}}>
                     <a href="https://storage.googleapis.com/testing_storage_cloudxier/domino_zumba_step4.jpg" target="_blank">
                        <img style={{width: '100%', height: '100%', borderRadius: '10px'}} src="https://storage.googleapis.com/testing_storage_cloudxier/domino_zumba_step4.jpg"></img>
                     </a>
                  </Box>
               </Typography>
            )
         case 'rf classic':
            return (
               <Typography>
                  Pilih <b>Tab Member</b>, kemudian pilih <b>Redeem Code</b> pada halaman website masing-masing game
                  <br /><br />
                  <Box sx={{width: '75vw', maxWidth: '400px', height: 'auto'}}>
                     <a href="https://storage.googleapis.com/testing_storage_cloudxier/rf_redeem_1.png" target="_blank">
                        <img style={{width: '100%', height: '100%', borderRadius: '10px'}} src="https://storage.googleapis.com/testing_storage_cloudxier/rf_redeem_1.png"></img>
                     </a>
                  </Box>
                  <br />
                  Silahkan loginkan <b>ID kamu</b>
                  <br /><br />
                  <Box sx={{width: '75vw', maxWidth: '400px', height: 'auto'}}>
                     <a href="https://storage.googleapis.com/testing_storage_cloudxier/rf_redeem_2.png" target="_blank">
                        <img style={{width: '100%', height: '100%', borderRadius: '10px'}} src="https://storage.googleapis.com/testing_storage_cloudxier/rf_redeem_2.png"></img>
                     </a>
                  </Box>
                  <br />
                  Masukan <b>Reedem Code</b> kemudian <b>klik Lanjut</b>
                  <br /><br />
                  <Box sx={{width: '75vw', maxWidth: '400px', height: 'auto'}}>
                     <a href="https://storage.googleapis.com/testing_storage_cloudxier/rf_redeem_3.png" target="_blank">
                        <img style={{width: '100%', height: '100%', borderRadius: '10px'}} src="https://storage.googleapis.com/testing_storage_cloudxier/rf_redeem_3.png"></img>
                     </a>
                  </Box>
                  <br />
                  Pilih Game
                  <br /><br />
                  <Box sx={{width: '75vw', maxWidth: '400px', height: 'auto'}}>
                     <a href="https://storage.googleapis.com/testing_storage_cloudxier/rf_redeem_4.png" target="_blank">
                        <img style={{width: '100%', height: '100%', borderRadius: '10px'}} src="https://storage.googleapis.com/testing_storage_cloudxier/rf_redeem_4.png"></img>
                     </a>
                  </Box>
                  <br />
                  Masukan <b>data-data kamu</b> kemudian <b>klik Lanjut</b>. <b>Nick karakter harus sesuai dengan di dalam game</b>
                  <br /><br />
                  <Box sx={{width: '75vw', maxWidth: '400px', height: 'auto'}}>
                     <a href="https://storage.googleapis.com/testing_storage_cloudxier/rf_redeem_5.png" target="_blank">
                        <img style={{width: '100%', height: '100%', borderRadius: '10px'}} src="https://storage.googleapis.com/testing_storage_cloudxier/rf_redeem_5.png"></img>
                     </a>
                  </Box>
                  <br />
                  Selamat! Proses Redeem Code telah berhasil dilakukan
                  <br /><br />
                  <Box sx={{width: '75vw', maxWidth: '400px', height: 'auto'}}>
                     <a href="https://storage.googleapis.com/testing_storage_cloudxier/rf_redeem_6.png" target="_blank">
                        <img style={{width: '100%', height: '100%', borderRadius: '10px'}} src="https://storage.googleapis.com/testing_storage_cloudxier/rf_redeem_6.png"></img>
                     </a>
                  </Box>
               </Typography>
            )
         case 'rf remastered':
            return (
               <Typography>
                  Pilih <b>Tab Member</b>, kemudian pilih <b>Redeem Code</b> pada halaman website masing-masing game
                  <br /><br />
                  <Box sx={{width: '75vw', maxWidth: '400px', height: 'auto'}}>
                     <a href="https://storage.googleapis.com/testing_storage_cloudxier/rf_redeem_1.png" target="_blank">
                        <img style={{width: '100%', height: '100%', borderRadius: '10px'}} src="https://storage.googleapis.com/testing_storage_cloudxier/rf_redeem_1.png"></img>
                     </a>
                  </Box>
                  <br />
                  Silahkan loginkan <b>ID kamu</b>
                  <br /><br />
                  <Box sx={{width: '75vw', maxWidth: '400px', height: 'auto'}}>
                     <a href="https://storage.googleapis.com/testing_storage_cloudxier/rf_redeem_2.png" target="_blank">
                        <img style={{width: '100%', height: '100%', borderRadius: '10px'}} src="https://storage.googleapis.com/testing_storage_cloudxier/rf_redeem_2.png"></img>
                     </a>
                  </Box>
                  <br />
                  Masukan <b>Reedem Code</b> kemudian <b>klik Lanjut</b>
                  <br /><br />
                  <Box sx={{width: '75vw', maxWidth: '400px', height: 'auto'}}>
                     <a href="https://storage.googleapis.com/testing_storage_cloudxier/rf_redeem_3.png" target="_blank">
                        <img style={{width: '100%', height: '100%', borderRadius: '10px'}} src="https://storage.googleapis.com/testing_storage_cloudxier/rf_redeem_3.png"></img>
                     </a>
                  </Box>
                  <br />
                  Pilih Game
                  <br /><br />
                  <Box sx={{width: '75vw', maxWidth: '400px', height: 'auto'}}>
                     <a href="https://storage.googleapis.com/testing_storage_cloudxier/rf_redeem_4.png" target="_blank">
                        <img style={{width: '100%', height: '100%', borderRadius: '10px'}} src="https://storage.googleapis.com/testing_storage_cloudxier/rf_redeem_4.png"></img>
                     </a>
                  </Box>
                  <br />
                  Masukan <b>data-data kamu</b> kemudian <b>klik Lanjut</b>. <b>Nick karakter harus sesuai dengan di dalam game</b>
                  <br /><br />
                  <Box sx={{width: '75vw', maxWidth: '400px', height: 'auto'}}>
                     <a href="https://storage.googleapis.com/testing_storage_cloudxier/rf_redeem_5.png" target="_blank">
                        <img style={{width: '100%', height: '100%', borderRadius: '10px'}} src="https://storage.googleapis.com/testing_storage_cloudxier/rf_redeem_5.png"></img>
                     </a>
                  </Box>
                  <br />
                  Selamat! Proses Redeem Code telah berhasil dilakukan
                  <br /><br />
                  <Box sx={{width: '75vw', maxWidth: '400px', height: 'auto'}}>
                     <a href="https://storage.googleapis.com/testing_storage_cloudxier/rf_redeem_6.png" target="_blank">
                        <img style={{width: '100%', height: '100%', borderRadius: '10px'}} src="https://storage.googleapis.com/testing_storage_cloudxier/rf_redeem_6.png"></img>
                     </a>
                  </Box>
               </Typography>
            )
         case 'pefect world 2':
            return (
               <Typography>
                  Pilih <b>Tab Member</b>, kemudian pilih <b>Redeem Code</b> pada halaman website masing-masing game
                  <br /><br />
                  <Box sx={{width: '75vw', maxWidth: '400px', height: 'auto'}}>
                     <a href="https://storage.googleapis.com/testing_storage_cloudxier/rf_redeem_1.png" target="_blank">
                        <img style={{width: '100%', height: '100%', borderRadius: '10px'}} src="https://storage.googleapis.com/testing_storage_cloudxier/rf_redeem_1.png"></img>
                     </a>
                  </Box>
                  <br />
                  Silahkan loginkan <b>ID kamu</b>
                  <br /><br />
                  <Box sx={{width: '75vw', maxWidth: '400px', height: 'auto'}}>
                     <a href="https://storage.googleapis.com/testing_storage_cloudxier/rf_redeem_2.png" target="_blank">
                        <img style={{width: '100%', height: '100%', borderRadius: '10px'}} src="https://storage.googleapis.com/testing_storage_cloudxier/rf_redeem_2.png"></img>
                     </a>
                  </Box>
                  <br />
                  Masukan <b>Reedem Code</b> kemudian <b>klik Lanjut</b>
                  <br /><br />
                  <Box sx={{width: '75vw', maxWidth: '400px', height: 'auto'}}>
                     <a href="https://storage.googleapis.com/testing_storage_cloudxier/rf_redeem_3.png" target="_blank">
                        <img style={{width: '100%', height: '100%', borderRadius: '10px'}} src="https://storage.googleapis.com/testing_storage_cloudxier/rf_redeem_3.png"></img>
                     </a>
                  </Box>
                  <br />
                  Pilih Game
                  <br /><br />
                  <Box sx={{width: '75vw', maxWidth: '400px', height: 'auto'}}>
                     <a href="https://storage.googleapis.com/testing_storage_cloudxier/rf_redeem_4.png" target="_blank">
                        <img style={{width: '100%', height: '100%', borderRadius: '10px'}} src="https://storage.googleapis.com/testing_storage_cloudxier/rf_redeem_4.png"></img>
                     </a>
                  </Box>
                  <br />
                  Masukan <b>data-data kamu</b> kemudian <b>klik Lanjut</b>. <b>Nick karakter harus sesuai dengan di dalam game</b>
                  <br /><br />
                  <Box sx={{width: '75vw', maxWidth: '400px', height: 'auto'}}>
                     <a href="https://storage.googleapis.com/testing_storage_cloudxier/rf_redeem_5.png" target="_blank">
                        <img style={{width: '100%', height: '100%', borderRadius: '10px'}} src="https://storage.googleapis.com/testing_storage_cloudxier/rf_redeem_5.png"></img>
                     </a>
                  </Box>
                  <br />
                  Selamat! Proses Redeem Code telah berhasil dilakukan
                  <br /><br />
                  <Box sx={{width: '75vw', maxWidth: '400px', height: 'auto'}}>
                     <a href="https://storage.googleapis.com/testing_storage_cloudxier/rf_redeem_6.png" target="_blank">
                        <img style={{width: '100%', height: '100%', borderRadius: '10px'}} src="https://storage.googleapis.com/testing_storage_cloudxier/rf_redeem_6.png"></img>
                     </a>
                  </Box>
               </Typography>
            )
         case 'atlantica rebirth':
            return (
               <Typography>
                  Pilih <b>Tab Member</b>, kemudian pilih <b>Redeem Code</b> pada halaman website masing-masing game
                  <br /><br />
                  <Box sx={{width: '75vw', maxWidth: '400px', height: 'auto'}}>
                     <a href="https://storage.googleapis.com/testing_storage_cloudxier/rf_redeem_1.png" target="_blank">
                        <img style={{width: '100%', height: '100%', borderRadius: '10px'}} src="https://storage.googleapis.com/testing_storage_cloudxier/rf_redeem_1.png"></img>
                     </a>
                  </Box>
                  <br />
                  Silahkan loginkan <b>ID kamu</b>
                  <br /><br />
                  <Box sx={{width: '75vw', maxWidth: '400px', height: 'auto'}}>
                     <a href="https://storage.googleapis.com/testing_storage_cloudxier/rf_redeem_2.png" target="_blank">
                        <img style={{width: '100%', height: '100%', borderRadius: '10px'}} src="https://storage.googleapis.com/testing_storage_cloudxier/rf_redeem_2.png"></img>
                     </a>
                  </Box>
                  <br />
                  Masukan <b>Reedem Code</b> kemudian <b>klik Lanjut</b>
                  <br /><br />
                  <Box sx={{width: '75vw', maxWidth: '400px', height: 'auto'}}>
                     <a href="https://storage.googleapis.com/testing_storage_cloudxier/rf_redeem_3.png" target="_blank">
                        <img style={{width: '100%', height: '100%', borderRadius: '10px'}} src="https://storage.googleapis.com/testing_storage_cloudxier/rf_redeem_3.png"></img>
                     </a>
                  </Box>
                  <br />
                  Pilih Game
                  <br /><br />
                  <Box sx={{width: '75vw', maxWidth: '400px', height: 'auto'}}>
                     <a href="https://storage.googleapis.com/testing_storage_cloudxier/rf_redeem_4.png" target="_blank">
                        <img style={{width: '100%', height: '100%', borderRadius: '10px'}} src="https://storage.googleapis.com/testing_storage_cloudxier/rf_redeem_4.png"></img>
                     </a>
                  </Box>
                  <br />
                  Masukan <b>data-data kamu</b> kemudian <b>klik Lanjut</b>. <b>Nick karakter harus sesuai dengan di dalam game</b>
                  <br /><br />
                  <Box sx={{width: '75vw', maxWidth: '400px', height: 'auto'}}>
                     <a href="https://storage.googleapis.com/testing_storage_cloudxier/rf_redeem_5.png" target="_blank">
                        <img style={{width: '100%', height: '100%', borderRadius: '10px'}} src="https://storage.googleapis.com/testing_storage_cloudxier/rf_redeem_5.png"></img>
                     </a>
                  </Box>
                  <br />
                  Selamat! Proses Redeem Code telah berhasil dilakukan
                  <br /><br />
                  <Box sx={{width: '75vw', maxWidth: '400px', height: 'auto'}}>
                     <a href="https://storage.googleapis.com/testing_storage_cloudxier/rf_redeem_6.png" target="_blank">
                        <img style={{width: '100%', height: '100%', borderRadius: '10px'}} src="https://storage.googleapis.com/testing_storage_cloudxier/rf_redeem_6.png"></img>
                     </a>
                  </Box>
               </Typography>
            )
         case 'ragnarok forever love':
            return (
               <Typography>
                  Login di website Ragnarok Retro (<b><a href="https://roretro.gnjoy.id/">https://roretro.gnjoy.id/</a></b>)
                  <br /><br />
                  <Box sx={{width: '75vw', maxWidth: '400px', height: 'auto'}}>
                     <a href="https://storage.googleapis.com/testing_storage_cloudxier/rofl_redeem_1.png" target="_blank">
                        <img style={{width: '100%', height: '100%', borderRadius: '10px'}} src="https://storage.googleapis.com/testing_storage_cloudxier/rofl_redeem_1.png"></img>
                     </a>
                  </Box>
                  <br />
                  Setelah masuk ke halaman Member Profile, pilih menu <b>Redeem Code</b>
                  <br /><br />
                  <Box sx={{width: '75vw', maxWidth: '400px', height: 'auto'}}>
                     <a href="https://storage.googleapis.com/testing_storage_cloudxier/rofl_redeem_2.png" target="_blank">
                        <img style={{width: '100%', height: '100%', borderRadius: '10px'}} src="https://storage.googleapis.com/testing_storage_cloudxier/rofl_redeem_2.png"></img>
                     </a>
                  </Box>
                  <br />
                  Tuliskan code pada menu redeem code
                  <br /><br />
                  <Box sx={{width: '75vw', maxWidth: '400px', height: 'auto'}}>
                     <a href="https://storage.googleapis.com/testing_storage_cloudxier/rofl_redeem_3.png" target="_blank">
                        <img style={{width: '100%', height: '100%', borderRadius: '10px'}} src="https://storage.googleapis.com/testing_storage_cloudxier/rofl_redeem_3.png"></img>
                     </a>
                  </Box>
                  <br />
                  Temui NPC Staff Event di Kota Alberta 148 60
                  <br /><br />
                  <Box sx={{width: '75vw', maxWidth: '400px', height: 'auto'}}>
                     <a href="https://storage.googleapis.com/testing_storage_cloudxier/rofl_redeem_4.jpg" target="_blank">
                        <img style={{width: '100%', height: '100%', borderRadius: '10px'}} src="https://storage.googleapis.com/testing_storage_cloudxier/rofl_redeem_4.jpg"></img>
                     </a>
                  </Box>
               </Typography>
            )
         case 'ragnarok retro':
            return (
               <Typography>
                  Login di website Ragnarok Retro (<b><a href="https://roretro.gnjoy.id/">https://roretro.gnjoy.id/</a></b>)
                  <br /><br />
                  <Box sx={{width: '75vw', maxWidth: '400px', height: 'auto'}}>
                     <a href="https://storage.googleapis.com/testing_storage_cloudxier/rofl_redeem_1.png" target="_blank">
                        <img style={{width: '100%', height: '100%', borderRadius: '10px'}} src="https://storage.googleapis.com/testing_storage_cloudxier/rofl_redeem_1.png"></img>
                     </a>
                  </Box>
                  <br />
                  Setelah masuk ke halaman Member Profile, pilih menu <b>Redeem Code</b>
                  <br /><br />
                  <Box sx={{width: '75vw', maxWidth: '400px', height: 'auto'}}>
                     <a href="https://storage.googleapis.com/testing_storage_cloudxier/rofl_redeem_2.png" target="_blank">
                        <img style={{width: '100%', height: '100%', borderRadius: '10px'}} src="https://storage.googleapis.com/testing_storage_cloudxier/rofl_redeem_2.png"></img>
                     </a>
                  </Box>
                  <br />
                  Tuliskan code pada menu redeem code
                  <br /><br />
                  <Box sx={{width: '75vw', maxWidth: '400px', height: 'auto'}}>
                     <a href="https://storage.googleapis.com/testing_storage_cloudxier/rofl_redeem_3.png" target="_blank">
                        <img style={{width: '100%', height: '100%', borderRadius: '10px'}} src="https://storage.googleapis.com/testing_storage_cloudxier/rofl_redeem_3.png"></img>
                     </a>
                  </Box>
                  <br />
                  Temui NPC Staff Event di Kota Alberta 148 60
                  <br /><br />
                  <Box sx={{width: '75vw', maxWidth: '400px', height: 'auto'}}>
                     <a href="https://storage.googleapis.com/testing_storage_cloudxier/rofl_redeem_4.jpg" target="_blank">
                        <img style={{width: '100%', height: '100%', borderRadius: '10px'}} src="https://storage.googleapis.com/testing_storage_cloudxier/rofl_redeem_4.jpg"></img>
                     </a>
                  </Box>
               </Typography>
            )
         case 'lost saga origin':
            return (
               <Typography>
                  Login di website Lost Saga Origin & Pilih menu Redeem (<b><a href='https://lostsaga.gnjoy.id/'>https://lostsaga.gnjoy.id/</a></b>)
                  <br /><br />
                  <Box sx={{width: '75vw', maxWidth: '400px', height: 'auto'}}>
                     <a href="https://storage.googleapis.com/testing_storage_cloudxier/lso_redeem_1.jpg" target="_blank">
                        <img style={{width: '100%', height: '100%', borderRadius: '10px'}} src="https://storage.googleapis.com/testing_storage_cloudxier/lso_redeem_1.jpg"></img>
                     </a>
                  </Box>
                  <br />
                  Login ID <b>GGL kamu</b>
                  <br /><br />
                  <Box sx={{width: '75vw', maxWidth: '400px', height: 'auto'}}>
                     <a href="https://storage.googleapis.com/testing_storage_cloudxier/lso_redeem_2.png" target="_blank">
                        <img style={{width: '100%', height: '100%', borderRadius: '10px'}} src="https://storage.googleapis.com/testing_storage_cloudxier/lso_redeem_2.png"></img>
                     </a>
                  </Box>
                  <br />
                  Masukkan kode Redeem lalu <b>Klik Tombol Redeem</b>
                  <br /><br />
                  <Box sx={{width: '75vw', maxWidth: '400px', height: 'auto'}}>
                     <a href="https://storage.googleapis.com/testing_storage_cloudxier/lso_redeem_3.png" target="_blank">
                        <img style={{width: '100%', height: '100%', borderRadius: '10px'}} src="https://storage.googleapis.com/testing_storage_cloudxier/lso_redeem_3.png"></img>
                     </a>
                  </Box>
                  <br />
                  Hadiah akan dikirim melalui <b>Storage Gift Box</b>
                  <br /><br />
                  <Box sx={{width: '75vw', maxWidth: '400px', height: 'auto'}}>
                     <a href="https://storage.googleapis.com/testing_storage_cloudxier/lso_redeem_4.png" target="_blank">
                        <img style={{width: '100%', height: '100%', borderRadius: '10px'}} src="https://storage.googleapis.com/testing_storage_cloudxier/lso_redeem_4.png"></img>
                     </a>
                  </Box>
               </Typography>
            )
         case 'pubg mobile':
            return (
               <Typography>
                  Redeem voucher pada platform ini, buka link ini: <b><a href="https://www.pubgmobile.com/act/a20180515iggamepc/">https://www.pubgmobile.com/act/a20180515iggamepc/</a></b>, dan masukkan detail akun anda (<b>Character ID dan Kode voucher</b>)
                  <br /><br />
                  <Box sx={{width: '75vw', maxWidth: '400px', height: 'auto'}}>
                     <a href="https://storage.googleapis.com/testing_storage_cloudxier/redeem_pubgm.png" target="_blank">
                        <img style={{width: '100%', height: '100%', borderRadius: '10px'}} src="https://storage.googleapis.com/testing_storage_cloudxier/redeem_pubgm.png"></img>
                     </a>
                  </Box>
                  <br />
                  Pastikan ID karakter dan kode voucher sudah benar, kemudian tekan <b>"OK"</b>
                  <br /><br />
                  <Box sx={{width: '75vw', maxWidth: '400px', height: 'auto'}}>
                     <a href="https://storage.googleapis.com/testing_storage_cloudxier/redeem_pubgm2.png" target="_blank">
                        <img style={{width: '100%', height: '100%', borderRadius: '10px'}} src="https://storage.googleapis.com/testing_storage_cloudxier/redeem_pubgm2.png"></img>
                     </a>
                  </Box>
                  <br />
                  Selamat anda sudah berhasil redeem voucher
                  <br /><br />
                  <Box sx={{width: '75vw', maxWidth: '400px', height: 'auto'}}>
                     <a href="https://storage.googleapis.com/testing_storage_cloudxier/redeem_pubgm3.png" target="_blank">
                        <img style={{width: '100%', height: '100%', borderRadius: '10px'}} src="https://storage.googleapis.com/testing_storage_cloudxier/redeem_pubgm3.png"></img>
                     </a>
                  </Box>
                  <br />
                  Buka aplikasi PUBG Mobile dan pergi ke halaman <b>Mail</b>, anda akan melihat hadiah redeem di mailbox, kemudian tekan <b>"Collect"</b>
                  <br /><br />
                  <Box sx={{width: '75vw', maxWidth: '400px', height: 'auto'}}>
                     <a href="https://storage.googleapis.com/testing_storage_cloudxier/redeem_pubgm4.PNG" target="_blank">
                        <img style={{width: '100%', height: '100%', borderRadius: '10px'}} src="https://storage.googleapis.com/testing_storage_cloudxier/redeem_pubgm4.PNG"></img>
                     </a>
                  </Box>
                  <br />
                  Hadiah sudah masuk ke inventory anda, silahkan digunakan
                  <br /><br />
                  <Box sx={{width: '75vw', maxWidth: '400px', height: 'auto'}}>
                     <a href="https://storage.googleapis.com/testing_storage_cloudxier/redeem_pubgm5.PNG" target="_blank">
                        <img style={{width: '100%', height: '100%', borderRadius: '10px'}} src="https://storage.googleapis.com/testing_storage_cloudxier/redeem_pubgm5.PNG"></img>
                     </a>
                  </Box>
               </Typography>
            )
         case 'ayodance mobile':
            return (
               <Typography>
                  <b>*Redeem coupon hanya bisa dilakukan pada perangkat AOS. Pada perangkat iOS tidak tersedia sesuai dengan peraturan Apple yang berlaku.</b>
                  <br />
                  <b>*Apabila pengguna iOS ingin melakukan redeem code, pemain harus melakukan bind Apple ID mereka dengan Duniapp dan login pada perangkat AOS untuk melakukan redeem Coupon Code.</b>
                  <br /><br />
                  Buka aplikasi <b>Ayodance Mobile</b> dan klik tombol gir di atas kanan layar
                  <br /><br />
                  <Box sx={{width: '75vw', maxWidth: '400px', height: 'auto'}}>
                     <a href="https://storage.googleapis.com/testing_storage_cloudxier/redeem_adm_1.jpg" target="_blank">
                        <img style={{width: '100%', height: '100%', borderRadius: '10px'}} src="https://storage.googleapis.com/testing_storage_cloudxier/redeem_adm_1.jpg"></img>
                     </a>
                  </Box>
                  <br />
                  Klik tombol <b>"Coupon"</b>
                  <br /><br />
                  <Box sx={{width: '75vw', maxWidth: '400px', height: 'auto'}}>
                     <a href="https://storage.googleapis.com/testing_storage_cloudxier/redeem_adm_2.jpg" target="_blank">
                        <img style={{width: '100%', height: '100%', borderRadius: '10px'}} src="https://storage.googleapis.com/testing_storage_cloudxier/redeem_adm_2.jpg"></img>
                     </a>
                  </Box>
                  <br />
                  Pop up akan muncul, dan silahkan masukkan 16 digit kode voucher dari platform ini ke dalam kotak yang disediakan dan klik tombol <b>"Ok"</b>
                  <br /><br />
                  <Box sx={{width: '75vw', maxWidth: '400px', height: 'auto'}}>
                     <a href="https://storage.googleapis.com/testing_storage_cloudxier/redeem_adm_3.jpg" target="_blank">
                        <img style={{width: '100%', height: '100%', borderRadius: '10px'}} src="https://storage.googleapis.com/testing_storage_cloudxier/redeem_adm_3.jpg"></img>
                     </a>
                  </Box>
                  <br />
                  Hadiah akan dikirimkan ke dalam fitur <b>"Mail"</b> pada game
               </Typography>
            )
         case 'ayodance':
            return (
               <Typography>
                  Buka <b><a href="https://rewards.megaxus.com">https://rewards.megaxus.com</a></b>
                  <br /><br />
                  <Box sx={{width: '75vw', maxWidth: '400px', height: 'auto'}}>
                     <a href="https://storage.googleapis.com/testing_storage_cloudxier/adpc_redeem_1.png" target="_blank">
                        <img style={{width: '100%', height: '100%', borderRadius: '10px'}} src="https://storage.googleapis.com/testing_storage_cloudxier/adpc_redeem_1.png"></img>
                     </a>
                  </Box>
                  <br />
                  Tuliskan kode voucher bonus dan klik <b>Validasi Kode</b>
                  <br /><br />
                  <Box sx={{width: '75vw', maxWidth: '400px', height: 'auto'}}>
                     <a href="https://storage.googleapis.com/testing_storage_cloudxier/adpc_redeem_2.png" target="_blank">
                        <img style={{width: '100%', height: '100%', borderRadius: '10px'}} src="https://storage.googleapis.com/testing_storage_cloudxier/adpc_redeem_2.png"></img>
                     </a>
                  </Box>
                  <br />
                  Tuliskan <b>Megaxus ID</b> kamu dan klik <b>Validasi</b>
                  <br /><br />
                  <Box sx={{width: '75vw', maxWidth: '400px', height: 'auto'}}>
                     <a href="https://storage.googleapis.com/testing_storage_cloudxier/adpc_redeem_3.png" target="_blank">
                        <img style={{width: '100%', height: '100%', borderRadius: '10px'}} src="https://storage.googleapis.com/testing_storage_cloudxier/adpc_redeem_3.png"></img>
                     </a>
                  </Box>
                  <br />
                  Voucher berhasil di-klaim. Hadiah sudah dikirimkan ke akun kamu
                  <br /><br />
                  <Box sx={{width: '75vw', maxWidth: '400px', height: 'auto'}}>
                     <a href="https://storage.googleapis.com/testing_storage_cloudxier/adpc_redeem_4.png" target="_blank">
                        <img style={{width: '100%', height: '100%', borderRadius: '10px'}} src="https://storage.googleapis.com/testing_storage_cloudxier/adpc_redeem_4.png"></img>
                     </a>
                  </Box>
               </Typography>
            )
          case 'tower of fantasy mobile':
            return (
                <Typography>
                  Klik tombol hadiah di pojok kanan atas layar
                  <br /><br />
                  <Box sx={{width: '75vw', maxWidth: '400px', height: 'auto'}}>
                      <a href="https://storage.googleapis.com/testing_storage_cloudxier/tof_redeem_1.jpg" target="_blank">
                        <img style={{width: '100%', height: '100%', borderRadius: '10px'}} src="https://storage.googleapis.com/testing_storage_cloudxier/tof_redeem_1.jpg"></img>
                      </a>
                  </Box>
                  <br />
                  Klik <b>"Reward"</b> pada bagian bawah layar
                  <br /><br />
                  <Box sx={{width: '75vw', maxWidth: '400px', height: 'auto'}}>
                      <a href="https://storage.googleapis.com/testing_storage_cloudxier/tof_redeem_2.jpg" target="_blank">
                        <img style={{width: '100%', height: '100%', borderRadius: '10px'}} src="https://storage.googleapis.com/testing_storage_cloudxier/tof_redeem_2.jpg"></img>
                      </a>
                  </Box>
                  <br />
                  Pada halaman Reward, klik <b>"Exchange"</b> pada bagian kiri layar
                  <br /><br />
                  <Box sx={{width: '75vw', maxWidth: '400px', height: 'auto'}}>
                      <a href="https://storage.googleapis.com/testing_storage_cloudxier/tof_redeem_3.jpg" target="_blank">
                        <img style={{width: '100%', height: '100%', borderRadius: '10px'}} src="https://storage.googleapis.com/testing_storage_cloudxier/tof_redeem_3.jpg"></img>
                      </a>
                  </Box>
                  <br />
                  Masukkan kode voucher yang didapatkan dari platform ini ke dalam kotak yang sudah disediakan dan klik <b>"Confirm"</b>
                  <br /><br />
                  <Box sx={{width: '75vw', maxWidth: '400px', height: 'auto'}}>
                      <a href="https://storage.googleapis.com/testing_storage_cloudxier/tof_redeem_4.jpg" target="_blank">
                        <img style={{width: '100%', height: '100%', borderRadius: '10px'}} src="https://storage.googleapis.com/testing_storage_cloudxier/tof_redeem_4.jpg"></img>
                      </a>
                  </Box>
                  <br />
                  Anda sudah berhasil redeem voucher, klik <b>"OK"</b>
                  <br /><br />
                  <Box sx={{width: '75vw', maxWidth: '400px', height: 'auto'}}>
                      <a href="https://storage.googleapis.com/testing_storage_cloudxier/tof_redeem_5.jpg" target="_blank">
                        <img style={{width: '100%', height: '100%', borderRadius: '10px'}} src="https://storage.googleapis.com/testing_storage_cloudxier/tof_redeem_5.jpg"></img>
                      </a>
                  </Box>
                  <br />
                  Kembali ke tampilan utama game, dan klik <b>"Friends"</b>
                  <br /><br />
                  <Box sx={{width: '75vw', maxWidth: '400px', height: 'auto'}}>
                      <a href="https://storage.googleapis.com/testing_storage_cloudxier/tof_redeem_6.jpg" target="_blank">
                        <img style={{width: '100%', height: '100%', borderRadius: '10px'}} src="https://storage.googleapis.com/testing_storage_cloudxier/tof_redeem_6.jpg"></img>
                      </a>
                  </Box>
                  <br />
                  Setelah masuk ke dalam, klik <b>"Mailbox"</b> dan buka pesan terakhir kemudian klik <b>"Claim"</b>, selamat anda telah berhasil redeem voucher dan hadiah langsung bertambah otomatis
                  <br /><br />
                  <Box sx={{width: '75vw', maxWidth: '400px', height: 'auto'}}>
                      <a href="https://storage.googleapis.com/testing_storage_cloudxier/tof_redeem_7.jpg" target="_blank">
                        <img style={{width: '100%', height: '100%', borderRadius: '10px'}} src="https://storage.googleapis.com/testing_storage_cloudxier/tof_redeem_7.jpg"></img>
                      </a>
                  </Box>
                </Typography>
            )
          case 'ragnarok origin':
            return (
                <Typography>
                  Buka game Ragnarok Origin di handphone atau PC anda
                  <br /><br />
                  Temukan tombol Reward (icon kotak hadiah), terletak di pojok kanan atas
                  <br /><br />
                  Tekan tombol Redeem yang berbentuk bell di pojok kanan atas
                  <br /><br />
                  Masukkan kode redeem Ragnarok Origin pada tempat yang telah disediakan
                  <br />
                  <Box sx={{width: '75vw', maxWidth: '400px', height: 'auto'}}>
                    <a href="https://storage.googleapis.com/testing_storage_cloudxier/roo_redeem_1.webp" target="_blank">
                      <img style={{width: '100%', height: '100%', borderRadius: '10px'}} src="https://storage.googleapis.com/testing_storage_cloudxier/roo_redeem_1.webp"></img>
                    </a>
                  </Box>
                  <br />
                  Click Confirm untuk klaim kode redeem Ragnarok Origin
                  <br />
                  Hadiah akan segera dikirimkan lewat pesan di Ragnarok Origin
                </Typography>
            )
         default:
            <></>
      }
   }

   return (
      <MainContainer isLoading={isLoading}>
         <Box
            sx={{ m: '0 -40px', pb: '25px' }}
         >
            <img src={game.thumbnail} alt={game.thumbnail} srcSet="" style={{ width: '100%', height: '375px', objectFit: 'cover', objectPosition: 'top' }} />
            <Box sx={{ p: '44px', pt: '30px' }}>
               <Typography variant='h5' sx={{ fontSize: 22, fontWeight: 'bold', color: '#5D2FE1', mb: '38px' }}>{game.name}</Typography>
               {/* <Box sx={{ display: 'flex', alignItems: 'center', gap: '6px', mb: '18px' }}>
                  <DiamondIcon />
                  <Typography sx={{ fontSize: 15, fontWeight: 'bold' }}>{game?.loved || 99}</Typography>
               </Box> */}
               <Typography variant='body1' sx={{ mb: '18px' }}>
                  {game.description}
               </Typography>
               <Accordion>
                  <AccordionSummary expandIcon={<ArrowDownIcon />}>
                     <Typography sx={{ color: '#5D2FE1' }}>How to claim in game</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                     {howToRedeem(game.name)}
                  </AccordionDetails>
               </Accordion>
               {showRedeemHistoryButton ? (
                  <Button
                     variant='contained'
                     onClick={() => navigate(`/redeem/${id}`)}
                  >
                     View voucher
                  </Button>
               ) : (
                  <Button
                     variant='contained'
                     onClick={handleRedeem}
                     disabled={!voucher.voucher_name}
                  >
                     Redeem now
                  </Button>
               )}
            </Box>
         </Box>
      </MainContainer>
   )
}

export default Game