import { Routes, Route } from "react-router-dom";
import { ThemeProvider } from '@mui/material/styles';

import { theme } from 'theme/theme'
import { SnackbarProvider } from "context/SnackbarContext";
import { LoadingProvider } from "context/LoadingContext";
import { UserProvider } from "context/UserContext";

import RequireAuth from "components/auth/RequireAuth"

import Home from "views/Home/HomePage/HomePage";
import Login from "views/Login/LoginPage/LoginPage";
import Game from "views/Game/GamePage/GamePage";
import Redeem from "views/Redeem/RedeemPage/RedeemPage";
import RedeemHistory from "views/RedeemHistory/RedeemHistoryPage/RedeemHistoryPage";
import Profile from "views/Profile/ProfilePage/ProfilePage"
import PageNotFound from "views/PageNotFound"

import './App.css';

function App() {
  return (
    <>
      <div className='container'>
        <ThemeProvider theme={theme}>
          <UserProvider>
            <LoadingProvider>
              <SnackbarProvider>
                <Routes>
                  <Route element={<RequireAuth />}>
                    <Route path="/" element={<Home />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/redeem-history" element={<RedeemHistory />} />
                    <Route path="/game/:id" element={<Game />} />
                    <Route path="/redeem/:id" element={<Redeem />} />
                  </Route>
                  <Route path="/login" element={<Login />} />
                  <Route path="/login/test" element={<Login />} />
                  <Route path="*" element={<PageNotFound />} />
                </Routes>
              </SnackbarProvider>
            </LoadingProvider>
          </UserProvider>
        </ThemeProvider>
      </div>
    </>
  );
}

export default App;