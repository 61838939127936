import React from 'react'

function QuestionMarkBubbleIcon({
   width = '26',
   height = '26',
   fill = "#5D2FE1"
}) {
   return (
      <svg
         width={width}
         height={height}
         viewBox="0 0 26 26"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            d="M13 0.5C19.9038 0.5 25.5 6.09625 25.5 13C25.5 19.9037 19.9038 25.5 13 25.5C10.9778 25.5027 8.98536 25.0127 7.19501 24.0725L1.83251 25.4688C1.65333 25.5154 1.46507 25.5145 1.28637 25.4659C1.10768 25.4174 0.94477 25.3231 0.81378 25.1922C0.682791 25.0613 0.588273 24.8985 0.539595 24.7199C0.490916 24.5412 0.489767 24.353 0.536262 24.1737L1.93126 18.8125C0.988512 17.0203 0.497225 15.0251 0.500012 13C0.500012 6.09625 6.09626 0.5 13 0.5ZM13 17.375C12.6685 17.375 12.3505 17.5067 12.1161 17.7411C11.8817 17.9755 11.75 18.2935 11.75 18.625C11.75 18.9565 11.8817 19.2745 12.1161 19.5089C12.3505 19.7433 12.6685 19.875 13 19.875C13.3315 19.875 13.6495 19.7433 13.8839 19.5089C14.1183 19.2745 14.25 18.9565 14.25 18.625C14.25 18.2935 14.1183 17.9755 13.8839 17.7411C13.6495 17.5067 13.3315 17.375 13 17.375ZM13 6.4375C12.0883 6.4375 11.214 6.79966 10.5693 7.44432C9.92468 8.08898 9.56251 8.96332 9.56251 9.875C9.56258 10.1125 9.65282 10.3412 9.81498 10.5147C9.97714 10.6883 10.1991 10.7938 10.4361 10.81C10.6731 10.8262 10.9074 10.7519 11.0916 10.602C11.2759 10.4521 11.3964 10.2378 11.4288 10.0025L11.4463 9.715C11.4885 9.31758 11.6814 8.95144 11.9853 8.69189C12.2892 8.43234 12.681 8.29911 13.0801 8.31961C13.4793 8.3401 13.8554 8.51276 14.1311 8.80207C14.4068 9.09138 14.5612 9.47534 14.5625 9.875C14.5625 10.5487 14.3938 10.8812 13.7563 11.54L13.3763 11.9275C12.4338 12.9075 12.0625 13.6112 12.0625 14.875C12.0625 15.1236 12.1613 15.3621 12.3371 15.5379C12.5129 15.7137 12.7514 15.8125 13 15.8125C13.2487 15.8125 13.4871 15.7137 13.6629 15.5379C13.8387 15.3621 13.9375 15.1236 13.9375 14.875C13.9375 14.2013 14.1063 13.8688 14.7438 13.21L15.1238 12.8225C16.0663 11.8425 16.4375 11.1388 16.4375 9.875C16.4375 8.96332 16.0753 8.08898 15.4307 7.44432C14.786 6.79966 13.9117 6.4375 13 6.4375Z"
            fill={fill}
         />
      </svg>
   )
}

export default QuestionMarkBubbleIcon