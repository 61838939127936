import React, { useRef, useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'

import TopMenu from 'components/layout/navigation/TopMenu'

import DenstvLogoDark from 'assets/Gamers_Code_Logo_on-light_ver.png'
import AvatarIcon from 'assets/AvatarIcon'
import ArrowLeftIcon from 'assets/ArrowLeftIcon'

function TopNav() {
   const location = useLocation();
   const navigate = useNavigate();
   const navRef = useRef(null)

   const [anchorEl, setAnchorEl] = useState(null);
   const open = Boolean(anchorEl);
   const handleClick = (event) => {
      setAnchorEl(navRef.current);
   };
   const handleClose = () => {
      setAnchorEl(null);
   };

   const [shadow, setShadow] = useState(false);

   const userEmail = window.localStorage.getItem('user_email')
   const userName = (userEmail && userEmail.split('@')[0]) || 'User'

   useEffect(() => {
      const handleScroll = () => window.pageYOffset > 0 ? setShadow(true) : setShadow(false);
      window.removeEventListener('scroll', handleScroll);
      window.addEventListener('scroll', handleScroll, { passive: true });
      return () => {
         window.removeEventListener("scroll", handleScroll)
      }
   }, [])

   return (
      <Box
         ref={navRef}
         sx={{
            maxWidth: '480px',
            m: '0 auto',
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            background: '#f5f5f5',
            zIndex: '100',
            boxShadow: shadow && '0px 4px 10px rgba(0, 0, 0, 0.08)',
         }}
      >
         <Container
            maxWidth="xs"
            sx={{
               display: 'flex',
               flexDirection: 'row',
               alignItems: 'center',
               justifyContent: 'space-between',
               height: 'max-content',
               minHeight: '96px'
            }}
         >
            {location.pathname === '/' ? (
               <div style={{width: '150px', height: 'auto'}}>
                  <img src={DenstvLogoDark} style={{width: '100%', height: '100%', objectFit: 'contain'}} />
               </div>
            ) : (
               <IconButton onClick={() => navigate(-1)}><ArrowLeftIcon /></IconButton>
            )}
            <Box
               sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
               }}
            >
               <Typography variant='body2'>Hi, {userName}</Typography>
               <TopMenu
                  button={
                     <Button
                        id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                     >
                        <AvatarIcon />
                     </Button>
                  }
                  open={open}
                  anchorEl={anchorEl}
                  handleClose={handleClose}
               />
            </Box>
         </Container>
      </Box>
   )
}

export default TopNav