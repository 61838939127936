import axios from "axios";

export const getServerURL = () => {
  const staging = "https://api-denstv-vms-stg.cloudxier.com"
  const localhost = "http://localhost:3789"
  const production = "https://api-denstv.cloudxier.com"
  // Get host from here
  let host = window.location.host;
  if (host) {
    host = host.toLowerCase();
  }
  let env
  switch (host) {
    case "denstv-vms.cloudxier.com":
      env = staging
      break;
    case "denstv.supreme-game.com":
      env = production
      break;
    default:
      env = localhost
  }
  return env
}

const api = axios.create({
   baseURL: getServerURL() || "http://localhost:3789",
   // baseURL: "https://api-denstv-vms-stg.cloudxier.com",
   headers: {
      'access-token': `${window.localStorage.getItem('access_token')}`,
      'Accept-Version': 1,
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json; charset=utf-8',
   }
});

export default api;