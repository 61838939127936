import React from 'react'
import { useForm, Controller } from 'react-hook-form'
import { Button, TextField, InputLabel, Box } from '@mui/material';

function LoginForm({
   handleSuccess
}) {

   let queryParams = new URLSearchParams(window.location.search);
   const emailParams = queryParams.get('email')

   const {
      control,
      handleSubmit,
      formState: {
         errors,
         isValid,
         isSubmitting
      }
   } = useForm({
      mode: 'onChange',
      defaultValues: {
         ISPUserID: process.env.REACT_APP_ISP_CHANNEL_REF || '',
         email: process.env.REACT_APP_EMAIL_ADDRESS || emailParams || '',
         ISPAgentRef: process.env.REACT_APP_ISP_AGENT_REF || ''
      }
   })

   const formOptions = {
      ISPUserID: {
         required: "ISP User ID is required",
         maxLength: {
            value: 255,
            message: "Can't be longer than 255 characters"
         },
      },
      ISPAgentRef: {
         required: "Agent reference is required",
         maxLength: {
            value: 255,
            message: "Can't be longer than 255 characters"
         },
      },
      email: {
         required: "Email is required",
         maxLength: {
            value: 255,
            message: "Can't be longer than 255 characters"
         },
      },
   }
   const onSubmit = (data) => {
      handleSuccess(data)
   }

   return (
      <Box sx={{
         padding: '0 20px',
         display: 'flex',
         flexDirection: 'column',
         justifyContent: 'center',
         alignItems: 'center',
         height: '50vh'
      }}>
         <form
            onSubmit={handleSubmit(onSubmit)}
         >

            {/* <Controller
               control={control}
               name="ISPUserID"
               rules={formOptions.ISPUserID}
               render={({ field: { onChange, value } }) => (
                  <div>
                     <InputLabel htmlFor="bootstrap-input">
                        ISP Channel Ref.
                     </InputLabel>
                     <TextField
                        fullWidth
                        autoComplete='off'
                        onChange={onChange}
                        value={value}
                        error={errors.ISPUserID}
                        variant="filled"
                        placeholder="e.g: CBNTGK21W"
                        InputProps={{
                           disableUnderline: true,
                        }}
                     />
                     <small style={{ userSelect: 'none', color: '#FFBA52', marginTop: '10px' }}>
                        {errors?.ISPUserID && (
                           <>
                              {errors.ISPUserID.message}
                              <br />
                           </>
                        )}
                     </small>
                  </div>
               )}
            />
            <Controller
               control={control}
               name="ISPAgentRef"
               rules={formOptions.ISPAgentRef}
               render={({ field: { onChange, value } }) => (
                  <div>
                     <InputLabel htmlFor="bootstrap-input">
                        ISP Agent Ref.
                     </InputLabel>
                     <TextField
                        fullWidth
                        autoComplete='off'
                        onChange={onChange}
                        value={value}
                        error={errors.ISPAgentRef}
                        variant="filled"
                        placeholder="e.g: CBN9R6OX"
                        InputProps={{
                           disableUnderline: true,
                        }}
                     />
                     <small style={{ userSelect: 'none', color: '#FFBA52', marginTop: '10px' }}>
                        {errors?.ISPAgentRef && (
                           <>
                              {errors.ISPAgentRef.message}
                              <br />
                           </>
                        )}
                     </small>
                  </div>
               )}
            /> */}
            <Controller
               control={control}
               name="email"
               rules={formOptions.email}
               render={({ field: { onChange, value } }) => {
                  return (
                     <div>
                        <InputLabel htmlFor="bootstrap-input">
                           Email Address
                        </InputLabel>
                        <TextField
                           fullWidth
                           autoComplete='off'
                           onChange={onChange}
                           value={value}
                           disabled={emailParams}
                           error={errors.email}
                           type="email"
                           variant="filled"
                           placeholder="e.g: john@example.com"
                           InputProps={{
                              disableUnderline: true,
                           }}
                        />
                        <small style={{ userSelect: 'none', color: '#FFBA52', marginTop: '10px' }}>
                           {errors?.email && (
                              <>
                                 {errors.email.message}
                                 <br />
                              </>
                           )}
                        </small>
                     </div>
                  )
               }}
            />

            <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
               <Button
                  size="small"
                  variant="contained"
                  type="submit"
                  disabled={!isValid || isSubmitting}
                  sx={{
                     m: 'auto',
                     mt: '40px',
                     width: 210,
                     padding: '8px',
                     color: 'white',
                     background: '#000000',
                     fontWeight: 'bold',
                     borderRadius: '15px',
                     '&:hover': {
                        filter: 'brightness(0.75)',
                     },
                     '&:disabled': {
                        background: '#FFEFC7',
                     }
                  }}
               >
                  Sign in
               </Button>
            </Box>
         </form>
      </Box>
   )
}

export default LoginForm